<template>
  <div class="training-zl">
    <!-- <router-view v-if="isRouterAlive"></router-view> -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>分类列表</span>
        <el-button
          style="float: right; padding: 5px 5px"
          type="success"
          @click="newList"
          >添加分类</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id" label="索引" align="center">
        </el-table-column>
        <el-table-column prop="name" label="名称" align="center">
        </el-table-column>
        <el-table-column prop="source" label="分类" align="center">
        </el-table-column>
        <el-table-column prop="type_zh" label="类型" align="center">
        </el-table-column>
        <el-table-column prop="status_zh" label="是否启用" align="center">
        </el-table-column>
        <el-table-column prop="add" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="exitVisible(scope, scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              class="delete"
              @click="delList(scope, scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
        <el-pagination
    layout="prev, pager, next"
    :page-size='pages.pageSize'
    :current-page="pages.startPage"
    @next-click="nextClick"
    @prev-click='prevClick'
    @current-change='currentChange'
    prev-text='上一页'
    next-text='下一页'
    :total="pages.total">
  </el-pagination>
    </div>
    <!-- 弹窗添加 -->
    <el-dialog
      title="添加分类"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="名称：">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="分类：">
          <el-select v-model="form.source" placeholder="请选择">
            <el-option
              v-for="item in source"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select v-model="form.type" placeholder="请选择">
            <el-option
              v-for="item in type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addList" v-if="openid == 1"
          >确 定</el-button
        >
        <el-button type="primary" @click="upList" v-else>保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  cateSave,
  cateDelete,
  cateUpdate,
  cateList,
  cateEdit
} from '@/api/zltype.js'
export default {
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      form: {
        name:null,
        source:null,
        type:null,
        status:null
      },
      // 科普  3
      source: [
        { value: 1, label: '培训资料' },
        { value: 2, label: '常见问题' },
        { value: 3, label: '科普宣教'  }
      ],
      type: [
        { value: 1, label: '视频类' },
        { value: 2, label: '图文类' }
      ],
      status: [
        { value: 1, label: '启用' },
        { value: 2, label: '停用' }
      ],
      openid: 1,
      id: null,
      index: null,
      isRouterAlive: true,
      pages:{
        startPage:1,
        total:null,
        pageSize:10
      }
    }
  },
  created () {
    this.getList(this.pages.startPage)
  },
  methods: {
    // 分页 下一页
    nextClick(size) {
      this.pages.startPage = size
      this.getList(size)
    },
    prevClick(size) {
      this.pages.startPage = size
      this.getList(size)
    },
    currentChange(size) {
      this.pages.startPage = size
      this.getList(size)
    },

    // 获取
    async getList (pages) {
      const res = await cateList({ page: pages })
      this.tableData = res.data.result.list
       this.pages.total = res.data.result.count
      const arr = res.data.result.list
      arr.forEach((item, index) => {
        if (item.source == 1) {
          this.tableData[index].source = '培训资料'
        } else if(item.source == 2) {
          this.tableData[index].source = '常见问题'
        }else {
          this.tableData[index].source = '科普宣教'
        }
      })
    },
    handleClose (done) {
      done()
      this.form.name = '',
      this.form.source = '',
      this.form.type = '',
      this.form.status = ''
    },
    newList () {
      this.openid = 1
      if(this.openid == 1) {
        this.dialogVisible = true
      }
    },
    // 增
    async addList () {
      const obj = {
        name: this.form.name,
        source: this.form.source,
        type: this.form.type,
        status: this.form.status
      }
      const res = await cateSave(obj)
      if (res.data.code == 1) {
        this.dialogVisible = false
        ;(this.form.name = ''),
          (this.form.source = ''),
          (this.form.type = ''),
          (this.form.status = '')
          this.$message.success('新增成功！')
        this.getList(this.pages.startPage)
      }
    },
    // 删
    async delList (scope, index, row) {
      const res = await cateDelete({ id: row.id })
      if (res.data.code == 1) {
        this.$message({
          message: '删除成功！',
          type: 'success'
        })
        this.tableData.splice(index, 1)
        this.getList(this.pages.startPage)
      }
    },
    // 改
    async exitVisible (scope, index, row) {
      this.index = scope.$index
      this.id = row.id
      this.openid = 2
      const res = await cateEdit({id: row.id})
      // this.form = this.tableData[index]
      this.form = res.data.result
      this.dialogVisible = true
    },
    // 改 保存
    async upList () {
      const obj = {
        name: this.form.name,
        source: this.form.source,
        type: this.form.type,
        status: this.form.status,
        id: this.id
      }
      const obj2 = {}
      obj2.id = this.id
      obj2.name = this.form.name
      obj2.source = this.form.source
      obj2.type = this.form.type
      obj2.status = this.form.status
      const res = await cateUpdate(obj)
      if (res.data.code == 1) {
        this.tableData[this.index] = obj2
        this.$message.success('修改成功！')
        this.getList(this.pages.startPage)
        this.dialogVisible = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.training-zl {
  padding: 20px 0;
  .box-card {
    .coverImg {
      width: 170px;
      height: 70px;
      border: 1px solid #ccc;
    }
  }
  .pagination{
    margin-top: 20px;
    text-align: right;
  }
  .delete {
    color: #f6423b;
  }
}
</style>
