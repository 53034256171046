import _fetch from "./request";
// 获取 admin/cate/list
export function cateList(query) {
    return _fetch({
      url: '/admin/cate/list',
      method: 'get',
      params:query,
      needToken:true
    })
}
// 增
export function cateSave(data) {
    return _fetch({
      url: '/admin/cate/save',
      method: 'post',
      data,
      needToken:true
    })
}
// 删
export function cateDelete(data) {
    return _fetch({
      url: '/admin/cate/delete',
      method: 'post',
      data,
      needToken:true
    })
}
// 改 保存
export function cateUpdate(data) {
    return _fetch({
      url: '/admin/cate/update',
      method: 'post',
      data,
      needToken:true
    })
} 
// 改 编辑
export function cateEdit(query) {
    return _fetch({
      url: '/admin/cate/edit',
      method: 'get',
      params:query,
      needToken:true
    })
}
// 资料分类列表 
export function materialCreate(query) {
  return _fetch({
    url: '/admin/material/create',
    method: 'get',
    params:query,
    needToken:true
  })
}
// 获取资料分类添加成功的列表
export function materialList(query) {
  return _fetch({
    url: '/admin/material/list',
    method: 'get',
    params:query,
    needToken:true
  })
}
// 资料分类保存
export function materialSave(data) {
  return _fetch({
    url: '/admin/material/save',
    method: 'post',
    data,
    needToken:true
  })
}
// 资料分类编辑
export function materialEdit(query) {
  return _fetch({
    url: '/admin/material/edit',
    method: 'get',
    params:query,
    needToken:true
  })
}
// 资料分类编辑提交admin/material/update
export function materialUpdate(data) {
  return _fetch({
    url: '/admin/material/update',
    method: 'post',
    data,
    needToken:true
  })
}
// 资料分类删除 admin/material/delete
export function materialDelete(data) {
  return _fetch({
    url: '/admin/material/delete',
    method: 'post',
    data,
    needToken:true
  })
}
